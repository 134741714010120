<template>
  <BaseListPage locale-section="pages.herNorway" route="herNorwayDetail">
    <HerNorwayList />
  </BaseListPage>
</template>

<script>
export default {
  name: "HerNorwayPage",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    HerNorwayList: () => import("./HerNorwayList")
  }
};
</script>
